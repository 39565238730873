<template>
  <v-dialog v-model="opened">
    <v-card class="basic-modal" elevation="8" style="width: 560px">
      <v-card-title class="d-flex justify-space-between">
        <h5 class="heading--text d-flex align-center">
          <span>{{ $t('heading.instance.advanced.modal.phpVersion.title') }}</span>
        </h5>
        <v-btn v-if="!submitting" icon x-small @click="opened = false">
          <v-icon size="24">$close</v-icon></v-btn
        >
      </v-card-title>

      <v-card-text>
        <div>
          <p class="mb-4 p-2 p-3">
            {{ $t('heading.instance.advanced.modal.phpVersion.field') }}
            <strong>{{ instance.domain }}</strong>
          </p>

          <v-form class="d-flex flex-column">
            <div class="d-flex flex-column">
              <div class="last-child">
                <v-select
                  outlined
                  v-model="version"
                  :items="versions"
                  hide-details="auto"
                  dense
                  append-icon="$dropdown"
                  :menu-props="{
                    offsetY: true,
                  }"
                />
              </div>
            </div>
          </v-form>
        </div>
      </v-card-text>

      <v-card-actions class="d-flex flex-wrap pb-4 pt-8">
        <div class="w-100 pb-2">
          <v-btn
            class="primary"
            large
            block
            elevation="0"
            @click="submit()"
            :loading="submitting"
          >
            <span class="p-2 font-weight-600">{{ $t('button.saveChanges') }}</span>
          </v-btn>
        </div>
        <div class="w-100 pb-2">
          <v-btn
            large
            block
            elevation="0"
            @click="opened = false"
            :disabled="submitting"
          >
            <span
              class="p-2"
              :class="'font-weight-light gray--text text--darken-1'"
              >{{ $t('button.cancel') }}
            </span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/apis/Api";

export default {
  components: {},
  props: {
    open: Boolean,
    instance: Object,
    currentVersion: String,
    versions: Array,
  },
  data() {
    return {
      submitting: false,
      version: "",
    };
  },
  computed: {
    opened: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("update:open", value);
      },
    },
  },
  watch: {
    opened(opened) {
      if (opened) {
        this.version = this.currentVersion;
      }
    },
  },
  methods: {
    submit() {
      this.submitting = true;
      Api.put(
        `/server-accounts/${this.instance.hostingId}/domains/${this.instance.domain}/php-version`,
        { php_version: this.version }
      )
        .then(() => {
          this.$emit("update:currentVersion", this.version);
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.settings.update')
          });
          this.opened = false;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style src="../../../../components/modal/BasicModal.scss" lang="scss" scoped />
