<template>
  <div>
    <v-container class="page-title page-title--advanced">
      <v-row>
        <v-col class="page-title__title-box">
          <h4 class="page-title__title">
            {{ $t("heading.instance.advanced.copyInstance.title") }}
          </h4>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" class="pb-2">
          <v-card elevation="4">
            <v-card-text
              class="d-flex justify-space-between text--gray-darken2"
            >
              <div>
                <h6>
                  {{ $t("heading.instance.advanced.copyInstance.copy.title") }}
                </h6>
                <p class="mb-0 mt-1">
                  {{
                    $t("heading.instance.advanced.copyInstance.copy.subtitle")
                  }}
                </p>
              </div>
              <div class="d-flex align-center">
                <v-btn
                  elevation="0"
                  class="mt-0 pt-0 font-weight-bold v-btn--default"
                  block
                  @click="openCopyInstanceModal()"
                >
                  <span class="font-weight-bold">{{
                    $t("button.copyInstance")
                  }}</span>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      :persistent="modalOptions.persistent"
      v-model="modalOptions.open"
      ref="dialog"
      transition="custom-dialog-transition"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()" />
      <basic-modal
        style="width: 560px"
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
        ref="modal"
      >
        <template v-slot:custom v-if="!isServiceSelected">
          <p class="p-3 mt-2">
            {{
              $t(
                "heading.instance.advanced.copyInstance.modal.selectService.message"
              )
            }}
          </p>
          <v-form ref="form">
            <v-select
              outlined
              :items="availableServices"
              v-model="selectedService"
              hide-details=""
              return-object
              validate-on-blur
              class="v-input--lg"
              item-value="id"
              :menu-props="{
                offsetY: true,
                nudgeBottom: '8px',
                closeOnContentClick: false,
                contentClass:
                  'custom-dropdown-select custom-dropdown-select--onboarding',
              }"
            >
              <template slot="selection" slot-scope="data">
                <template>{{ data.item.plan_name }} </template>
                <template
                  >({{
                    $t(
                      "heading.onboarding.chooseProduct.product.instancesLimit",
                      {
                        count: data.item.instance_count,
                        limit: data.item.instance_limit,
                      }
                    )
                  }})</template
                >
                <template v-if="data.item.hosting_accounts.length">
                  - {{ data.item.hosting_accounts[0].domain }}</template
                >
              </template>
              <template slot="item" slot-scope="data">
                <template>{{ data.item.plan_name }} </template>
                <template
                  >({{
                    $t(
                      "heading.onboarding.chooseProduct.product.instancesLimit",
                      {
                        count: data.item.instance_count,
                        limit: data.item.instance_limit,
                      }
                    )
                  }})</template
                >
                <template v-if="data.item.hosting_accounts.length">
                  - {{ data.item.hosting_accounts[0].domain }}</template
                >
              </template>
            </v-select>
            <v-scale-transition>
              <div
                v-if="
                  selectedService.instance_count >=
                  selectedService.instance_limit
                "
                class="warning-box my-5"
              >
                <v-icon color="warning" size="20" class="mr-2"
                  >$alertcircle</v-icon
                >
                <span class="warning--text">
                  {{
                    $t(
                      "heading.onboarding.chooseProduct.product.fullServiceAlert"
                    )
                  }}
                </span>
              </div>
            </v-scale-transition>
          </v-form>
        </template>
      </basic-modal>
    </v-dialog>
  </div>
</template>

<script>
import ActionModalMixin from "../../../mixins/ActionModalMixin";
import BasicModal from "../../../components/modal/BasicModal.vue";
import Api from "../../../apis/Api";

export default {
  components: {
    BasicModal,
  },
  mixins: [ActionModalMixin],
  data: function () {
    return {
      selectedService: {},
      isServiceSelected: false,
    };
  },
  props: {
    instance: Object,
  },
  computed: {
    availableServices() {
      return this.$store.state.services.data;
    },
  },
  methods: {
    openCopyInstanceModal() {
      this.resetModal();
      this.modalOptions.title = this.$t(
        "heading.instance.advanced.copyInstance.copy.title"
      );
      this.modalOptions.icon = "$copy";
      this.modalOptions.buttons.unshift({
        label: this.$t("button.copyInstance"),
        color: "primary",
        onclick: () => {
          if (this.selectedService.onboarding.user_configurable_options.length > 0) {
            this.isServiceSelected = true;
            this.showUserConfigurableOptionsModal();
            return;
          }
          this.copyInstance();
        },
      });
      this.modalOptions.open = true;
    },
    showUserConfigurableOptionsModal() {
      this.resetModal();
      this.modalOptions.title = this.$t("heading.instance.advanced.copyInstance.copy.title");
      this.modalOptions.icon = "$copy";

      this.modalOptions.buttons.unshift({
        label: this.$t("button.copyInstance"),
        color: "primary",
        onclick: (item) => {
          this.copyInstance(item.formValues);
        },
      });

      this.modalOptions.item = {};

      this.selectedService.onboarding.user_configurable_options.forEach((option) => {
        this.modalOptions.formFields.push(
          {
            label: this.$t(`form.label.${option.name}`),
            name: option.name,
            type: option.type,
            options: option.options.map(item => {
              return {
                label: item.text,
                value: item.value
              }
            }),
          }
        );
        this.modalOptions.item[option.name] = option.value;
      })

      this.modalOptions.open = true;
    },
    copyInstance(form = {}) {
      this.modalOptions.submitting = true;

      Api.post(`/instances/${this.instance.id}/copy`, {
        service_id: this.selectedService.id,
        ...form
      })
        .then(() => {
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t(
              "notification.instance.copyInstance.completed"
            ),
          });
          this.modalClose();
        })
        .catch((error) => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },
  },
  watch: {
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");

      this.selectedService = this.availableServices[0];
      this.isServiceSelected = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-modal::v-deep {
  ul {
    margin-top: 16px;

    li {
      font-weight: $font-weight-semibold;
      position: relative;
      padding-left: 16px;
      color: #333342;

      &::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #ff7a2f;
        top: calc(50% - 3px);
        left: 0px;
      }

      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }
  .v-input {
    &.v-select.v-input--lg {
      .v-input__control .v-input__slot {
        input {
          display: none;
        }
      }
    }
  }
}

.custom-dropdown-select--onboarding::v-deep {
  overflow-y: auto !important;
  padding-left: 16px;
  .v-list-item {
    color: unset !important;
    caret-color: unset !important;
  }
}

.custom-dropdown-select--onboarding {
  display: none !important;
}
</style>
